import {Routes} from '@angular/router'
import {LOGIN_ROUTE, registeredGuard} from '@ez-court/fe-theme'
import {ADMIN_HOME_PATH} from './application/constants'

export const routes: Routes = [
  LOGIN_ROUTE,
  {
    path: ADMIN_HOME_PATH,
    loadComponent: () => import('./admin/home/home.component').then(m => m.HomeComponent),
    canActivate: [registeredGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ADMIN_HOME_PATH
  }
]
